/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Button.module.css";
import { ProgressIndicator } from "@asosteam/asos-web-component-library-progress-indicator";

export default function Button({
  data,
  loading,
  size,
  style,
  buttonRef,
  onClick,
  ...rest
}) {
  const text = data?.text ?? false;
  const href = data?.link?.href ?? false;
  const target = data?.link?.target ?? null;
  const isCaps = data?.isCaps ?? true;
  const dataPage = data?.dataPage ?? "";
  const Component = href ? "a" : "button";

  if (!text) {
    return null;
  }

  if (loading) {
    return <ProgressIndicator progressMessage="loading" size="small" />;
  }

  const classes = classNames({
    [`${styles.button}`]: true,
    [`${styles[size]}`]: true,
    [`${styles[style]}`]: true,
    [`${styles.isCaps}`]: isCaps,
  });

  const props = {
    ...rest,
    ...(dataPage && { "data-page": dataPage }),
    ...(href && { href }),
    ...(buttonRef && { ref: buttonRef }),
    ...(target && { target }),
    ...(onClick && { onClick }),
  };

  return (
    <Component {...props} className={classes}>
      {text}
    </Component>
  );
}

Button.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
    }),
    isCaps: PropTypes.bool,
    dataPage: PropTypes.number,
  }),
  style: PropTypes.oneOf(["white", "black", "grey"]).isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
  loading: PropTypes.bool.isRequired,
  buttonRef: PropTypes.func,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  style: "white",
  size: "large",
  onClick: null,
  loading: false,
};
