import Delivery from "./icons/delivery.svg";
import Returns from "./icons/returns.svg";
import Orders from "./icons/my-orders.svg";
import ProductStock from "./icons/clothing.svg";
import Payments from "./icons/credit-card.svg";
import Technical from "./icons/settings.svg";
import RightArrow from "./icons/chevron-right.svg";
import Plus from "./icons/plus.svg";
import Search from "./icons/search-large.svg";
import Minus from "./icons/minus.svg";
import Email from "./icons/email.svg";
import ChatOnline from "./icons/chat-online.svg";
import ChatOffline from "./icons/chat-offline.svg";
import ROE from "./icons/roe.svg";
import ROW from "./icons/row.svg";
import Chevron from "./icons/chevron-down.svg";

const iconsMap = (iconId) => {
  const icons = {
    "Chat Online": ChatOnline,
    "Chat Offline": ChatOffline,
    "Delivery": Delivery,
    "Email": Email,
    "minus": Minus,
    "Order Issues": Orders,
    "Payments Promos Vouchers": Payments,
    "plus": Plus,
    "Product Stock": ProductStock,
    "Related Article": RightArrow,
    "Returns Refunds": Returns,
    "Search": Search,
    "Technical": Technical,
    "ROE": ROE,
    "ROW": ROW,
    "Chevron": Chevron,
  };

  const icon = icons[iconId];

  if (typeof icon === "undefined") {
    return "";
  }

  return icon;
};

export default iconsMap;
