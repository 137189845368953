import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./Main.module.css";

export default function Main({ children, flex, fullwidth, margin }) {
  if (!children) return null;

  const classes = classNames({
    [`${styles.main}`]: true,
    [`${styles.flex}`]: flex,
    [`${styles.hasSidebar}`]: !fullwidth,
    [`${styles.fullwidth}`]: fullwidth,
    [`${styles.margin}`]: margin,
  });

  return <div className={classes}>{children}</div>;
}

Main.defaultProps = {
  flex: false,
  fullwidth: false,
  margin: true,
};

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  flex: PropTypes.bool.isRequired,
  fullwidth: PropTypes.bool.isRequired,
  margin: PropTypes.bool.isRequired,
};
